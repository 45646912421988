<template>
  <div>
    <table-filter
      :individual="individual"
      :skillsItems="skillItems"
      :ageItems="ageGroupItems"
      :types="types"
      @getData="setFilterData($event)"
      @clearData="clearFilterData"
    />
    <v-row class="mb-2">
      <v-col class="d-flex justify-end"
        ><v-btn color="primary" @click="$router.push('/Exercise/add-exercise')"
          ><span style="color: white;">{{ $t("add_exercise") }}</span></v-btn
        ></v-col
      >
    </v-row>

    <data-table
      @updatePage="getData($event)"
      :headers="tableData.headers"
      :data="tableData.data"
      :editable="edit"
      :deleteable="deleteItem"
      :copyable="copy"
      :loader="loader"
      :meta="pagination"
    />
    <delete-popup
      @closePopup="deleteDialog = false"
      @deleteData="deleteReq($event)"
      :dialog="deleteDialog"
      :item="deleteDialogData"
      :loader="deleteLoader"
    />
  </div>
</template>
<script>
import { Exercise } from "@/store/exercise/add.js";
import { Skilles } from "@/store/exercise/skilles";
import { ageGroup } from "@/store/exercise/age-group";
import dataTable from "@/components/mycomponent/base/dataTable.vue";
import tableFilter from "./exerciseFilter.vue";
import deletePopup from "../age-group/delete.vue";
export default {
  components: {
    dataTable,
    deletePopup,
    tableFilter,
  },
  data() {
    return {
      Exercise,
      Skilles,
      ageGroup,
      deleteDialog: false,
      deleteDialogData: null,
      filterData: {},
    };
  },
  methods: {
    getData(page) {
      let params = {
        page: page,
        name: this.filterData.name,
        serial: this.filterData.serial,
        description: this.filterData.description,
        age_group_id: this.filterData.age_group_id,
        individual_differences: this.filterData.individual_differences,
        type: this.filterData.type,
        created_from: this.filterData.created_from,
        created_to: this.filterData.created_to,
        sortby_created: this.filterData.sortby_created,
      };
      Exercise.get(params);
    },
    edit(obj) {
      this.$router.push(`/Exercise/edit-exercise/${obj.id}`);
    },
    deleteItem(obj) {
      (this.deleteDialog = true), (this.deleteDialogData = obj);
    },
    async deleteReq(id) {
      await Exercise.delete(id);
      this.deleteDialog = false;
    },
    copy(obj) {
      this.$router.push(`/Exercise/copy-exercise/${obj.id}`);
    },
    setFilterData(data) {
      this.filterData = data;
      this.getData(1);
    },
    clearFilterData() {
      this.filterData = {};
      this.getData(1);
    },
  },
  computed: {
    tableData() {
      return Exercise.tableData;
    },
    loader() {
      return Exercise.getState.loading;
    },
    deleteLoader() {
      return Exercise.deleteState.loading;
    },
    pagination() {
      return Exercise.tableData.meta;
    },
    skillItems() {
      return Skilles.tableData.data;
    },
    ageGroupItems() {
      return ageGroup.tableData.data;
    },
    typesItems() {
      return Exercise.types;
    },
    individual() {
      return ["easy", "medium", "hard"];
    },
    types() {
      return Exercise.types;
    },
  },
  mounted() {
    this.getData(1);
    Skilles.get({ page: 1 });
    ageGroup.get({ page: 1 });
  },
};
</script>
